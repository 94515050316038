import React, { useState, useEffect } from "react";
import "./App.css";
import Card from "./components/Card";
import Badge from "./components/Badge";
import Button from "./components/Button";
import AreaChart from "./components/AreaChart";
import RCarousel from "./components/RCaousel";

import bookmark from "./assets/icons/bookmark.svg";
import bolt from "./assets/icons/icons8-lightning-40.png";
import balance from "./assets/icons/icons8-balance-scale-48.png";
import growth from "./assets/icons/icons8-growth-48.png";
import lock from "./assets/icons/icons8-locked-with-key-48.png";
import money from "./assets/icons/icons8-money-bag-48.png";
import buckets from "./assets/icons/buckets.png";
import usdc from "./assets/icons/tokens/usdc.svg";
import dai from "./assets/icons/tokens/dai.svg";
import btc from "./assets/icons/tokens/btc.svg";
import aave from "./assets/icons/tokens/aave.svg";
import eth from "./assets/icons/tokens/eth.svg";

import { TermsOfServiceModal, PrivacyPolicy, RiskDisclaimer } from "./legalDocs";

function App() {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [imageSrc, setImageSrc] = useState(`${process.env.PUBLIC_URL}/assets/screenshot_buckets_page.png`);
  const [opacity, setOpacity] = useState({ img1: 1, img2: 0 });
  const [navbarTextColor, setNavbarTextColor] = useState("text-white"); // default color
  const [logoSrc, setLogoSrc] = useState("/Logo.png"); // Default logo
  var ReactRotatingText = require("react-rotating-text");
  const [showTerms, setShowTerms] = useState(false);

  const handleTermsClick = () => {
    setShowTerms(true);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;

    // Logic for image opacity transition
    const startTransition = 200;
    const endTransition = 300;
    let opacityImg1, opacityImg2;
    if (position <= startTransition) {
      opacityImg1 = 1;
      opacityImg2 = 0;
    } else if (position >= endTransition) {
      opacityImg1 = 0;
      opacityImg2 = 1;
    } else {
      const progress = (position - startTransition) / (endTransition - startTransition);
      opacityImg1 = 1 - progress;
      opacityImg2 = progress;
    }
    setOpacity({ img1: opacityImg1, img2: opacityImg2 });

    // Logic for top bar blur effect
    setIsScrolled(position > 0);

    // Logic for changing navbar text color
    const whiteBgElementPosition = document.getElementById("features").offsetTop; // Adjust ID accordingly
    const whiteBgElementHeight = document.getElementById("features").offsetHeight; // Adjust ID accordingly

    console.log(position >= whiteBgElementPosition);
    console.log(position < whiteBgElementPosition + whiteBgElementHeight);
    if (position >= whiteBgElementPosition && position < whiteBgElementPosition + whiteBgElementHeight) {
      setNavbarTextColor("text-dark"); // Change to black when over white background
      setLogoSrc("/Logo_dark.png"); // Change to dark version of the logo
    } else {
      setNavbarTextColor("text-white"); // Change back to white otherwise
      setLogoSrc("/Logo.png"); // Change back to default logo
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const dummyData = [1, 2, 3, 4, 5, 6];
  const dummyNames = ["Ethereum DeFi", "BTC & Stablecoins", "CRV/BTC/AAVE @ Aave Avalanche", "Avalanche CRV/USDT/USDC", "AAVE & Stablecoins @ Aave", "Ethereum BTC/USDT/ETH"];
  const dummyDesc = ["Diversified allocation of 1inch, USDT & USDC on the Aave protocol", "Balanced allocation of BTC & USDT & USDC", "Diversify with CRV, BTC and USDC on Aave on the Avalanche network.", "Earn potential returns with CRV and USDT/USDC on the Avalanche network.", "AAVE, USDT and USDC on the Aave protocol to generate a stable yield.", "Combine BTC and USDT & ETH on Ethereum for a balanced strategy."];
  const dummyProtocols = ["https://icons.llama.fi/aave-v3.png", "https://icons.llama.fi/compound.png", "https://icons.llama.fi/curve.png", "https://icons.llama.fi/curve.png", "https://icons.llama.fi/aave-v3.png", "https://icons.llama.fi/uniswap.png"];

  const dummyCompo = [
    ["1inch", "usdt", "usdc"],
    ["btc", "usdt", "usdc"],
    ["crv", "btc", "usdc"],
    ["crv", "usdt", "usdc"],
    ["aave", "usdt", "usdc"],
    ["btc", "usdt", "eth"],
  ];
  const dummyChain = [["https://assets.coingecko.com/coins/images/279/large/ethereum.png?1696501628"], ["https://assets.coingecko.com/coins/images/279/large/ethereum.png?1696501628"], ["https://icons.llama.fi/avalanche.png"], ["https://icons.llama.fi/avalanche.png"], ["https://assets.coingecko.com/coins/images/279/large/ethereum.png?1696501628"], ["https://assets.coingecko.com/coins/images/279/large/ethereum.png?1696501628"]];

  const getDateDaysAgo = (daysAgo) => {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${monthNames[date.getMonth()]} ${date.getDate()}`;
  };

  const dumpChartData = [
    [
      {
        date: getDateDaysAgo(4),
        value: 30,
      },
      {
        date: getDateDaysAgo(3),
        value: 20,
      },
      {
        date: getDateDaysAgo(2),
        value: 50,
      },
      {
        date: getDateDaysAgo(1),
        value: 40,
      },
    ],
    [
      {
        date: getDateDaysAgo(5),
        value: 10,
      },
      {
        date: getDateDaysAgo(4),
        value: 12,
      },
      {
        date: getDateDaysAgo(3),
        value: 40,
      },
      {
        date: getDateDaysAgo(2),
        value: 30,
      },
      {
        date: getDateDaysAgo(1),
        value: 60,
      },
    ],
    [
      {
        date: getDateDaysAgo(5),
        value: 18,
      },
      {
        date: getDateDaysAgo(4),
        value: 12,
      },
      {
        date: getDateDaysAgo(3),
        value: 28,
      },
      {
        date: getDateDaysAgo(2),
        value: 22,
      },
      {
        date: getDateDaysAgo(1),
        value: 60,
      },
    ],
    [
      {
        date: getDateDaysAgo(5),
        value: 60,
      },
      {
        date: getDateDaysAgo(4),
        value: 66,
      },
      {
        date: getDateDaysAgo(3),
        value: 88,
      },
      {
        date: getDateDaysAgo(2),
        value: 75,
      },
      {
        date: getDateDaysAgo(1),
        value: 69,
      },
    ],
    [
      {
        date: getDateDaysAgo(5),
        value: 10,
      },
      {
        date: getDateDaysAgo(4),
        value: 12,
      },
      {
        date: getDateDaysAgo(3),
        value: 40,
      },
      {
        date: getDateDaysAgo(2),
        value: 30,
      },
      {
        date: getDateDaysAgo(1),
        value: 60,
      },
    ],
    [
      {
        date: getDateDaysAgo(4),
        value: 20,
      },
      {
        date: getDateDaysAgo(3),
        value: 10,
      },
      {
        date: getDateDaysAgo(2),
        value: 8,
      },
      {
        date: getDateDaysAgo(1),
        value: 9,
      },
    ],
  ];

  const handleChange = (e) => {
    setEmail(e.target.value);
    const isValid = validateEmail(e.target.value);
    setIsEmailValid(isValid);
  };

  const validateEmail = (email) => {
    // Simple email format validation using regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    if (!isEmailValid || !email) {
      e.preventDefault();
      alert("Please enter a valid email address.");
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://getlaunchlist.com/js/widget-diy.js";
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="min-h-screen dark">
      <div className={`fixed top-0 left-0 w-full z-10 px-4 ${isScrolled ? "bg-transparent backdrop-blur-md" : ""}`}>
        <div className="container mx-auto">
          <div className={`flex items-center justify-between ${navbarTextColor}`}>
            <img src={logoSrc} alt="Alternate text" className="w-24 sm:w-40 md:w-48 lg:w-[260px] mt-2 md:mt-6" />

            <div className="flex space-x-2 sm:space-x-4">
              <a className="text-sm sm:text-lg no-underline hover:text-pink-500 h-10 p-2 md:h-auto md:p-4 mt-4 md:mt-6" href="https://docs.buckets.fi" target="_blank" rel="noopener noreferrer">
                About
              </a>
              <button className="bg-blue-500 text-sm sm:text-lg text-white py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out h-9 md:h-auto md:p-4 mt-4 md:mt-6" onClick={() => window.open("https://app.buckets.fi", "_blank")}>
                Open app
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-24 lg:pt-40 flex flex-col lg:flex-row items-center mb-4 md:mb-16 mx-4 lg:mx-24">
        <div className="flex flex-col w-full justify-center mb-4 lg:mb-0">
          <h1 className="my-2 md:my-4 text-4xl md:text-5xl lg:text-6xl text-white text-center font-bold leading-tight">
            <span className="rotating-text text-blue-500">
              <ReactRotatingText items={["Digital assets", "Web3", "Yields"]} />
            </span>
            made simple
          </h1>
          <p className="leading-normal text-center md:text-xl lg:text-2xl mb-8 text-white">
            Grow your wealth with <span className="text-blue-500">smart</span> and <span className="text-blue-500">social investing</span> tools <img src={bolt} alt="lightning bolt" className="h-4 md:h-6 lg:h-8 inline-block" />
          </p>
          <div className="flex items-center justify-center pt-4 mb-8">
            <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out" onClick={() => window.open("https://app.buckets.fi", "_blank")}>
              Open app 🪣
            </button>
          </div>

          <div className="relative mt-0 lg:mt-8 flex justify-center">
            <img src={`${process.env.PUBLIC_URL}/assets/screenshot_buckets_page.png`} alt="Original Buckets page" className="transition-image rounded-sm shadow-xl mt-0 md:mt-4 w-full md:w-3/4 lg:w-5/6 absolute" style={{ opacity: opacity.img1 }} />
            <img src={`${process.env.PUBLIC_URL}/assets/screenshot_buckets_page_white.png`} alt="White Buckets page" className="transition-image rounded-sm shadow-xl mt-0 md:mt-4 w-full md:w-3/4 lg:w-5/6 " style={{ opacity: opacity.img2 }} />
          </div>
        </div>
      </div>

      <div className="container mt-0 mx-auto px-4 md:px-8 flex flex-wrap flex-col md:flex-row items-center mb-8">
        <div className="text-white w-full md:w-full p-4 md:px-0 overflow-hidden text-center">
          <div className="flex justify-between">
            <div className="text-center flex-1">
              <h2 className="my-2 md:my-4 text-2xl md:text-5xl lg:text-6xl font-bold">200+</h2>
              <p className="text-sm md:text-md">buckets created</p>
            </div>
            <div className="text-center flex-1">
              <h2 className="my-2 md:my-4 text-2xl md:text-5xl lg:text-6xl font-bold">1k+</h2>
              <p className="text-sm md:text-md">risk scores</p>
            </div>
            <div className="text-center flex-1">
              <h2 className="my-2 md:my-4 text-2xl md:text-5xl lg:text-6xl font-bold">5k+</h2>
              <p className="text-sm md:text-md">on waitlist</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center px-4 pt-4 mb-16">
        <form className="bg-gray w-full md:w-1/2 shadow-lg rounded-lg px-8 pt-6 pb-8" action="https://getlaunchlist.com/s/0ldWYW" onSubmit={handleSubmit} method="POST" target="_blank">
          <div className="mb-2">
            <label className="block text-blue-300 py-2 font-bold mb-2" htmlFor="emailaddress">
              Join the community
            </label>
            <input className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:ring transform transition hover:scale-105 duration-300 ease-in-out" id="emailaddress" type="text" name="email" placeholder="you@somewhere.com" onChange={handleChange} value={email} />
            <p className="mt-2 text-xs text-white italic">We respect your privacy and will only notify you about app updates.</p>
          </div>

          <div className="flex items-center justify-between pt-4">
            <button className="bg-gradient-to-r from-purple-800 to-green-500 hover:from-pink-500 hover:to-green-500 text-white font-bold py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out" type="submit">
              I'm in!
            </button>
          </div>
        </form>
      </div>

      <div className="scrolling-text-container mt-8">
        <div className="scrolling-text italic font-bold">
          <span>Permissionless</span>
          <span>Decentralized Finance</span>
          <span>Non-custodial</span>
          <span>Smart Contracts</span>
          <span>Open-Source</span>
          <span>Interoperability</span>
          <span>Programmable Money</span>
          <span>Wallets</span>
          <span>Financial inclusion</span>
          <span>Digital Assets</span>
          <span>Cryptocurrencies</span>
          <span>Transparency</span>
          <span>Permissionless</span>
          <span>Decentralized Finance</span>
          <span>Non-custodial</span>
          <span>Smart Contracts</span>
          <span>Open-Source</span>
          <span>Interoperability</span>
          <span>Programmable Money</span>
          <span>Wallets</span>
          <span>Financial inclusion</span>
          <span>Digital Assets</span>
          <span>Cryptocurrencies</span>
          <span>Transparency</span>
          <span>Permissionless</span>
          <span>Decentralized Finance</span>
          <span>Non-custodial</span>
          <span>Smart Contracts</span>
          <span>Open-Source</span>
          <span>Interoperability</span>
          <span>Programmable Money</span>
          <span>Wallets</span>
          <span>Financial inclusion</span>
          <span>Digital Assets</span>
          <span>Cryptocurrencies</span>
          <span>Transparency</span>
          <span>Permissionless</span>
          <span>Decentralized Finance</span>
          <span>Non-custodial</span>
          <span>Smart Contracts</span>
          <span>Open-Source</span>
          <span>Interoperability</span>
          <span>Programmable Money</span>
          <span>Wallets</span>
          <span>Financial inclusion</span>
          <span>Digital Assets</span>
          <span>Cryptocurrencies</span>
          <span>Transparency</span>
        </div>
      </div>

      <section id="benefits" className="benefits">
        <div className="container mx-auto flex flex-wrap flex-col md:flex-row items-center mb-4">
          <div className="text-white w-full md:w-full px-4 md:p-12 p-0 overflow-hidden text-center">
            <h1 className="my-2 md:my-4 text-3xl md:text-4xl lg:text-5xl text-white text-center font-bold leading-tight">When finance meets technology...</h1>
            <img src={process.env.PUBLIC_URL + "/assets/icons8-double-down.gif"} alt="Animated arrow" className="mx-auto mt-8" style={{ filter: "invert(100%)" }} />
          </div>
        </div>

        <div className="container my-0 lg:my-8 mx-auto flex flex-nowrap flex-col lg:flex-row items-center">
          <div className="text-white w-full lg:w-4/6 lg:pl-12 p-4 lg:px-0 overflow-hidden">
            <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start mb-2">
              <img src={process.env.PUBLIC_URL + "/assets/icons8-security-lock-96.png"} alt="Lock" className="w-12 h-12 mb-4 lg:mb-0 lg:mr-8" />
              <h1 className="text-center lg:text-start text-2xl lg:text-3xl font-bold">Security through cryptography</h1>
            </div>

            <p className="text-grey text-center lg:text-start text-xl lg:text-2xl mb-8">Powerful cryptography makes it impossible for anyone to access your funds without your secret seed phrase.</p>
          </div>

          <div className="text-white w-full lg:w-4/6 lg:pl-12 p-4 lg:px-0 overflow-hidden">
            <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start mb-2">
              <img src={process.env.PUBLIC_URL + "/assets/icons8-eye-96.png"} alt="Eye as a symbol for transparency" className="w-12 h-12 mb-4 lg:mb-0 lg:mr-8" />
              <h1 className="text-center lg:text-start text-2xl lg:text-3xl font-bold">High-speed & transparency</h1>
            </div>

            <p className="text-grey text-center lg:text-start text-xl lg:text-2xl mb-8">You always know exactly where your money is, thanks to real-time updates and a public ledger of transactions.</p>
          </div>

          <div className="text-white w-full lg:w-4/6 lg:pl-12 p-4 lg:px-0 overflow-hidden">
            <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start mb-2">
              <img src={process.env.PUBLIC_URL + "/assets/icons8-access-96.png"} alt="A key representing ownership" className="w-12 h-12 mb-4 lg:mb-0 lg:mr-8" />
              <h1 className="text-center lg:text-start text-2xl lg:text-3xl font-bold">Immutable ownership</h1>
            </div>

            <p className="text-grey text-center lg:text-start text-xl lg:text-2xl mb-8">Say hello to a new era of social experience on the internet, where you're the owner of the content you create!</p>
          </div>
        </div>

        <div className="flex items-center justify-center pt-4 mb-8">
          <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out" onClick={() => window.open("https://app.buckets.fi", "_blank")}>
            Open app 🪣
          </button>
        </div>
      </section>

      <section id="features" className="features">
        <div className="container mx-auto flex flex-wrap flex-col md:flex-row items-center mb-4">
          <div className="w-full md:w-full md:p-12 p-0 overflow-hidden text-center">
            <h1 className="my-2 md:my-4 text-3xl md:text-4xl lg:text-5xl text-center font-bold leading-tight">
              Unlock <span className="text-blue-500">financial opportunities</span> with Buckets
            </h1>
          </div>
        </div>

        <div className="container my-0 md:my-32 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div className="w-full md:w-4/6 md:pl-12 p-4 md:px-0 overflow-hidden">
            <h1 className="text-center md:text-start text-2xl md:text-3xl font-bold mb-4 mx-2">
              Explore a selection of thrilling<span className="text-blue-500"> investments</span>
            </h1>
            <p className="text-light text-center md:text-start text-xl md:text-2xl mb-8 mx-2">Access financial assets not available at your local bank, such as cryptocurrencies, tokenized assets, and more! 10%+ yield opportunities are only one click away.</p>
          </div>

          <div className="flex flex-col sm:w-full md:w-2/6 justify-center lg:items-start overflow-y-hidden mb-6">
            <div className="hidden md:flex w-full h-full shadow-md flex items-center justify-center">
              <img src={process.env.PUBLIC_URL + "/assets/app_wallet_screenshot.png"} alt="Screenshot of Buckets app" className="object-cover w-5/5 h-5/5 rounded-2xl" />
            </div>
          </div>
        </div>

        <div className="container my-0 md:my-32 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div className="flex flex-col w-full md:w-3/6 justify-center lg:items-start overflow-y-hidden mb-6 px-4">
            <div className="flex flex-col w-full justify-center items-center lg:items-start overflow-y-hidden mb-4 text-white">
              <div className="w-2/3 shadow-md">
                <RCarousel data={Array.from({ length: 7 })}>
                  {Array.from({ length: 7 }, (_, i) => (
                    <div className="mx-4 bg-gray rounded-xl" key={i + 1}>
                      <img src={`${process.env.PUBLIC_URL}/assets/buckets/${i + 1}.jpg`} className="rounded-2xl" />
                    </div>
                  ))}
                </RCarousel>
              </div>
            </div>
          </div>

          <div className="w-full md:w-3/6 md:pl-12 p-4 md:px-0 overflow-hidden">
            <h1 className="text-center md:text-start text-2xl md:text-3xl font-bold mb-4 mx-2">
              A <span className="text-blue-500">social</span> investing experience
            </h1>
            <p className="text-light text-center md:text-start text-xl md:text-2xl mb-8 mx-2">Create your profile and browse through users' buckets. Invest in those that inspire you or create your own and receive rewards when other investors use them.</p>
          </div>
        </div>

        <div className="container my-0 md:my-32 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div className="w-full md:w-4/6 md:pl-12 p-4 md:px-0 overflow-hidden">
            <h1 className="text-center md:text-start text-2xl md:text-3xl font-bold mb-4 mx-2">
              Access cutting-edge <span className="text-blue-500">risk</span> scores
            </h1>
            <p className="text-light text-center md:text-start text-xl md:text-2xl mb-8 mx-2">Understanding your risk is an important part of investing. Discover advanced analytics, simulate your scenarios, and fit your investments to your risk tolerance.</p>
          </div>

          <div className="flex flex-col sm:w-full md:w-2/6 justify-center lg:items-start overflow-y-hidden mb-6">
            <div className="w-full h-full shadow-md flex items-center justify-center">
              <img src={process.env.PUBLIC_URL + "/assets/Risk Score Dark.png"} alt="DeFi and Cryptocurrencies Analytics tools for Portfolio Management" className="object-cover w-3/5 h-3/5 md:w-4/5 md:h-4/5 rounded-2xl" />
            </div>
          </div>
        </div>

        <div className="container my-0 md:my-32 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div className="w-full p-4 overflow-hidden">
            <h1 className="text-center text-2xl md:text-3xl font-bold mt-8">
              Trade on <span className="text-blue-500">multi-chains</span> easily
            </h1>
          </div>

          <div className="w-full overflow-hidden">
            <div className="w-full h-full shadow-md flex items-center justify-center">
              <img src={process.env.PUBLIC_URL + "/assets/logo_multichains2.png"} alt="Multi-chains with logos of Ethereum, Avalance, Polygon, and OP ecosystems" className="object-cover w-full h-3/5 rounded-2xl" />
            </div>
          </div>
        </div>
      </section>

      <div className="container mt-8 mx-auto flex flex-wrap flex-col md:flex-row items-center mb-2">
        <div className="text-white w-full md:w-full p-4 md:px-0 overflow-hidden text-center">
          <h1 className="text-3xl md:text-4xl font-bold my-4">Successful founders backed by the best!</h1>
          <div className="flex items-center justify-center flex-col">
            {/* Partners Section */}
            <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-16 md:space-x-24 lg:space-x-36 my-0 md:my-16">
              <a target="_blank" rel="noopener noreferrer" href="https://www.aave.com">
                <img src="/assets/partners/aave/aave-logo.svg" className="w-40 sm:w-40 md:w-40 lg:w-48 mb-4 sm:mb-0" alt="Aave Logo" />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.startup.google.com">
                <img src="/assets/partners/google/Google_for_Startups_logo.svg" className="w-48 sm:w-48 md:w-48 lg:w-64 mb-12 sm:mb-0" alt="Google for Startups Logo" />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.gitcoin.co">
                <img src="/assets/partners/gitcoin/gitcoin-gtc-logo.svg" className="w-20 sm:w-20 md:w-20 lg:w-28" alt="Gitcoin Logo" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center pt-4 mb-16">
        <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out" onClick={() => window.open("https://app.buckets.fi", "_blank")}>
          Open app 🪣
        </button>
      </div>

      <div className="container mt-8 mx-auto flex justify-center items-start mb-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col justify-center">
            <div className="bg-gray-100 shadow-md rounded-lg p-8 text-center mb-4 mx-4 md:mx-0 flex flex-col justify-center" style={{ height: "200px" }}>
              <h1 className="text-center text-2xl md:text-3xl font-bold mb-2">Non custodial</h1>
              <p className="text-light text-md lg:text-xl">Nobody can access your assets or invest your funds without your consent.</p>
            </div>

            <div className="bg-gray-100 shadow-md rounded-lg p-8 text-center mb-0 md:mb-4 mx-4 md:mx-0 flex flex-col justify-center" style={{ height: "200px" }}>
              <h1 className="text-center text-2xl md:text-3xl font-bold mb-2">Every asset, every chain</h1>
              <div className="scrolling-text-container2">
                <div className="scrolling-text2">
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/aave.svg`} alt="Aave token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ageur.svg`} alt="ageur token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/btc.svg`} alt="bitcoin token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/dai.svg`} alt="DAI token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ens.svg`} alt="ENS token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ftm.svg`} alt="Fantom token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ldo.svg`} alt="Lido token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/matic.svg`} alt="Polygon Matic token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/mkr.svg`} alt="Maker token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/reth.svg`} alt="Rocket ETH Ethereum token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/snx.svg`} alt="Synthetix token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/uni.svg`} alt="Uniswap Protocol token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/aave.svg`} alt="Aave token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ageur.svg`} alt="ageur token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/btc.svg`} alt="bitcoin token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/dai.svg`} alt="DAI token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ens.svg`} alt="ENS token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ftm.svg`} alt="Fantom token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ldo.svg`} alt="Lido token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/matic.svg`} alt="Polygon Matic token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/mkr.svg`} alt="Maker token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/reth.svg`} alt="Rocket ETH Ethereum token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/snx.svg`} alt="Synthetix token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/uni.svg`} alt="Uniswap Protocol token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/aave.svg`} alt="Aave token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/ageur.svg`} alt="ageur token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/btc.svg`} alt="bitcoin token cryptocurrency" className="w-20 h-20" />
                  </span>
                  <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/tokens/dai.svg`} alt="DAI token cryptocurrency" className="w-20 h-20" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center">
            <div className="bg-gray-100 shadow-md rounded-lg p-8 text-center mb-4 mx-4 md:mx-0 flex flex-col justify-center" style={{ height: "200px" }}>
              <h1 className="text-center text-2xl md:text-3xl font-bold mb-2">A wallet with an email address</h1>
              <p className="text-light text-md lg:text-xl">Don't want to save your seed phrase? Create a new account with your email. Easy as A-B-C.</p>
            </div>
            <div className="bg-gray-100 shadow-md rounded-lg p-8 text-center mb-4 mx-4 md:mx-0 flex flex-col justify-center" style={{ height: "200px" }}>
              <h1 className="text-center text-2xl md:text-3xl font-bold mb-2">Decentralized governance</h1>
              <p className="text-light text-md lg:text-xl">Join the discord server and participate in taking decisions that have an impact.</p>
            </div>
          </div>
        </div>
      </div>

      <footer className="w-full pt-16 pb-6 text-sm light px-4 md:px-20">
        <div className="flex flex-col md:flex-row justify-between items-start">
          <div className="text-white text-left mb-4 md:mb-0">
            <p>
              Copyright © 2024 All rights reserved
              <br />A Dapp made with ❤️ by
              <a href="https://twitter.com/buckets_fi" target="_blank" rel="noopener noreferrer">
                @buckets_fi
              </a>
            </p>
          </div>
          <div className="flex text-white italic">
            <TermsOfServiceModal />
            <PrivacyPolicy />
            <RiskDisclaimer />
          </div>
        </div>
        <div className="flex items-start">
          <a className="text-blue-300 no-underline hover:text-pink-500 h-10 p-2 md:h-auto md:p-4 mt-4 md:mt-6" href="https://www.x.com/buckets_fi" target="_blank">
            <svg className="fill-current h-4 sm:h-5 md:h-6 mt-0 md:mt-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path d="M30.063 7.313c-.813 1.125-1.75 2.125-2.875 2.938v.75c0 1.563-.188 3.125-.688 4.625a15.088 15.088 0 0 1-2.063 4.438c-.875 1.438-2 2.688-3.25 3.813a15.015 15.015 0 0 1-4.625 2.563c-1.813.688-3.75 1-5.75 1-3.25 0-6.188-.875-8.875-2.625.438.063.875.125 1.375.125 2.688 0 5.063-.875 7.188-2.5-1.25 0-2.375-.375-3.375-1.125s-1.688-1.688-2.063-2.875c.438.063.813.125 1.125.125.5 0 1-.063 1.5-.25-1.313-.25-2.438-.938-3.313-1.938a5.673 5.673 0 0 1-1.313-3.688v-.063c.813.438 1.688.688 2.625.688a5.228 5.228 0 0 1-1.875-2c-.5-.875-.688-1.813-.688-2.75 0-1.063.25-2.063.75-2.938 1.438 1.75 3.188 3.188 5.25 4.25s4.313 1.688 6.688 1.813a5.579 5.579 0 0 1 1.5-5.438c1.125-1.125 2.5-1.688 4.125-1.688s3.063.625 4.188 1.813a11.48 11.48 0 0 0 3.688-1.375c-.438 1.375-1.313 2.438-2.563 3.188 1.125-.125 2.188-.438 3.313-.875z"></path>
            </svg>
          </a>
          <a className="text-blue-300 no-underline hover:text-pink-500 h-10 p-2 md:h-auto md:p-4 mt-4 md:mt-6" href="https://discord.com/invite/6xUwnug39W" target="_blank">
            <svg className="fill-current h-5 sm:h-6 md:h-8 mt-0 md:mt-0" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-discord" viewBox="0 0 24 24">
              <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
            </svg>
          </a>
          <a className="text-blue-300 no-underline hover:text-pink-500 h-10 p-2 md:h-auto md:p-4 mt-4 md:mt-6" href="https://www.linkedin.com/company/buckets-fi/" target="_blank">
            <svg className="fill-current h-4 sm:h-5 md:h-6 mt-0 md:mt-0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </a>
        </div>
        <div className="text-white italic mt-4 md:px-0">
          <p>There are risks associated with investing in securities. The content presented on this website is for informational purposes only, you should not construe any such information or other material as legal, tax, investment, financial, or other advice. Nothing contained on this website constitutes a solicitation, recommendation, endorsement, or offer to buy or sell any securities or other financial instruments. Please contact us for any questions or inquiries</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
